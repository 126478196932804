import Button from '@material-ui/core/esm/Button';
import ListItem from '@material-ui/core/esm/ListItem';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarMenuListItem = (props) => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    label: Label,
    disabled,
    ...rest
  } = props;

  let paddingLeft = 14;

  if (depth > 0) {
    paddingLeft = 47 + 20 * depth;
  }

  const style = {
    paddingLeft,
  };

  if (children) {
    return (
      <ListItem {...rest} disableGutters>
        {children}
      </ListItem>
    );
  }
  return (
    <ListItem
      {...rest}
      disableGutters
      className={clsx('app-sidebar-item', className)}
    >
      <Button
        disableRipple
        // activeClassName="active-item"
        className={clsx('app-sidebar-button-wrapper', `depth-${depth}`)}
        color="primary"
        component={CustomRouterLink}
        disabled={disabled}
        exact="true"
        style={style}
        to={href}
        variant="text"
      >
        {Icon && <Icon className="app-sidebar-icon" />}
        {title}
        {Label && (
          <span className="menu-item-label">
            <Label />
          </span>
        )}
      </Button>
    </ListItem>
  );
};

SidebarMenuListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

SidebarMenuListItem.defaultProps = {
  depth: 0,
  open: false,
};

export default SidebarMenuListItem;
