import { Box, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import projectLogo from '../../assets/images/Cleo Logo.svg';

const HeaderLogo = (props) => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <div
      className={clsx('app-header-logo', {
        'app-header-logo-close': sidebarToggle,
        'app-header-logo-open': sidebarHover,
      })}
    >
      <Box
        className="header-logo-wrapper"
        title="Carolina React Admin Dashboard with Material-UI PRO"
      >
        <Link className="header-logo-wrapper-link" to="/payin">
          <IconButton
            className="header-logo-wrapper-btn"
            color="primary"
            size="medium"
          >
            <img
              alt="Carolina React Admin Dashboard with Material-UI PRO"
              className="app-header-logo-img"
              src={projectLogo}
            />
          </IconButton>
        </Link>
        <Box className="header-logo-text">Cleo Transfer</Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(HeaderLogo);
