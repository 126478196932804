import { Box, IconButton, Tooltip } from '@material-ui/core';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import projectLogo from '../../assets/images/Cleo Logo.svg';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

const SidebarHeader = function ({
  sidebarToggleMobile,
  setSidebarToggleMobile,
  setSidebarToggle,
  sidebarToggle,
}) {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <div
      className={clsx('app-sidebar-header', {
        'app-sidebar-header-close': sidebarToggle,
      })}
    >
      <Box
        className="header-logo-wrapper"
        title="Carolina React Admin Dashboard with Material-UI PRO"
      >
        <Link className="header-logo-wrapper-link" to="/payin">
          <IconButton
            className="header-logo-wrapper-btn"
            color="primary"
            size="medium"
          >
            <img
              alt="Carolina React Admin Dashboard with Material-UI PRO"
              className="app-sidebar-logo"
              src={projectLogo}
            />
          </IconButton>
        </Link>
        <Box className="header-logo-text">Cleo Transfer</Box>
      </Box>
      <Box
        className={clsx('app-sidebar-header-btn', {
          'app-sidebar-header-btn-close': sidebarToggle,
        })}
      >
        <Tooltip placement="right" title="Toggle Sidebar">
          <IconButton color="inherit" size="medium" onClick={toggleSidebar}>
            {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      <Box className="app-sidebar-header-btn-mobile">
        <Tooltip placement="right" title="Toggle Sidebar">
          <IconButton
            color="inherit"
            size="medium"
            onClick={toggleSidebarMobile}
          >
            {sidebarToggleMobile ? (
              <MenuOpenRoundedIcon />
            ) : (
              <MenuRoundedIcon />
            )}
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
};
const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
