import AppBar from '@material-ui/core/esm/AppBar';
import Box from '@material-ui/core/esm/Box';
import Hidden from '@material-ui/core/esm/Hidden';
import IconButton from '@material-ui/core/esm/IconButton';
import Tooltip from '@material-ui/core/esm/Tooltip';
import MenuOpenRoundedIcon from '@material-ui/icons/esm/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/esm/MenuRounded';
import clsx from 'clsx';
import { getTimezoneOffset } from 'date-fns-tz';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import projectLogo from '../../assets/images/Cleo Logo.svg';
import { COUNTRIES_DICT, COUNTRIES_TIME_ZONE } from '../../constants/Countries';
import { useAuthContext } from '../../providers/AuthProvider';
import {
  setSidebarToggle,
  setSidebarToggleMobile,
} from '../../reducers/ThemeOptions';
import HeaderLogo from '../HeaderLogo';
import HeaderUserbox from '../HeaderUserbox';

const Header = ({
  sidebarToggleMobile,
  setSidebarToggleMobile,
  setSidebarToggle,
  sidebarToggle,
  isCollapsedLayout,
}) => {
  const { role, country, setCookie } = useAuthContext();

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  let offsetHoursString = '';
  if (country) {
    const today = new Date();
    const result = getTimezoneOffset(COUNTRIES_TIME_ZONE[country], today) / (60 * 60 * 1000);

    const parts = COUNTRIES_TIME_ZONE[country].split('/');
    const city = parts[1]; // 'Santiago'

    offsetHoursString = `GMT${result} (${city})`;
  }

  return (
    <AppBar
      className={clsx('app-header', {
        'app-header-collapsed-sidebar': isCollapsedLayout,
      })}
      color="secondary"
      elevation={11}
      position="fixed"
    >
      {!isCollapsedLayout && <HeaderLogo />}
      <Box className="app-header-toolbar">
        <Hidden lgUp>
          <Box
            className="app-logo-wrapper"
            title="Carolina React Admin Dashboard with Material-UI PRO"
          >
            <Link className="app-logo-link" to="/payin">
              <IconButton
                className="app-logo-btn"
                color="primary"
                size="medium"
              >
                <img
                  alt="Carolina React Admin Dashboard with Material-UI PRO"
                  className="app-logo-img"
                  src={projectLogo}
                />
              </IconButton>
            </Link>
            <Hidden smDown>
              <Box className="app-logo-text">Cleo Transfer</Box>
            </Hidden>
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box className="d-flex align-items-center">
            {!isCollapsedLayout && (
              <Box
                className={clsx('btn-toggle-collapse', {
                  'btn-toggle-collapse-closed': sidebarToggle,
                })}
              >
                <Tooltip placement="right" title="Toggle Sidebar">
                  <IconButton
                    className="btn-inverse"
                    color="inherit"
                    size="medium"
                    onClick={toggleSidebar}
                  >
                    {sidebarToggle ? (
                      <MenuRoundedIcon />
                    ) : (
                      <MenuOpenRoundedIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Hidden>
        <Box className="d-flex align-items-center">
          {role === 'ADMIN' ? (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {
                  COUNTRIES_DICT.map((cc) => (
                    <Box
                      key={cc}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 15px',
                        margin: '0 5px',
                        border: `1px solid ${
                          country === cc ? 'white' : 'transparent'
                        }`,
                        borderRadius: 10,
                      }}
                      onClick={() => {
                        setCookie('country', cc, { path: '/' });
                      }}
                    >
                      {cc}
                    </Box>
                  ))
                }
              </Box>
              <Box style={{
                padding: '5px 15px',
                margin: '0 5px',
              }}
              >
                {offsetHoursString}
              </Box>
            </Box>
          ) : null}
          {role === 'MERCHANT' ? (
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                key={country}
                style={{
                  padding: '5px 15px',
                  margin: '0 5px',
                  border: '1px solid white',
                  borderRadius: 10,
                }}
              >
                {country}
              </Box>
              <Box style={{
                padding: '5px 15px',
                margin: '0 5px',
              }}
              >
                {offsetHoursString}
              </Box>
            </Box>
          ) : null}
          <HeaderUserbox />
          <Box className="toggle-sidebar-btn-mobile">
            <Tooltip placement="right" title="Toggle Sidebar">
              <IconButton
                color="inherit"
                size="medium"
                onClick={toggleSidebarMobile}
              >
                {sidebarToggleMobile ? (
                  <MenuOpenRoundedIcon />
                ) : (
                  <MenuRoundedIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
