import {
  createContext, useCallback,
  useContext, useEffect,
  useMemo, useState,
} from 'react';
import { useCookies } from 'react-cookie';

export const AuthContext = createContext(null);

const initialAuthData = {
  token: null,
  name: null,
  role: null,
  isMerchantAlias: 'no',
};

const AuthProvider = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'name', 'role', 'isMerchantAlias', 'country']);
  const [authData, setAuthData] = useState(initialAuthData);

  const onLogout = useCallback(() => {
    removeCookie('token', { path: '/' });
    removeCookie('name', { path: '/' });
    removeCookie('role', { path: '/' });
    removeCookie('isMerchantAlias', { path: '/' });
    removeCookie('country', { path: '/' });
    setAuthData(initialAuthData);
  }, [removeCookie]);

  const onLogin = useCallback(
    (newAuthData) => {
      const {
        token, name, role, expiresAt, isMerchantAlias, country,
      } = newAuthData;
      const expiresAtDate = new Date(expiresAt);
      setCookie('token', token, { path: '/', expires: expiresAtDate });
      setCookie('name', name, { path: '/', expires: expiresAtDate });
      setCookie('role', role, { path: '/', expires: expiresAtDate });
      setCookie('isMerchantAlias', isMerchantAlias ? 'yes' : 'no', { path: '/', expires: expiresAtDate });
      setCookie('country', country, { path: '/', expires: expiresAtDate });
      setAuthData(newAuthData);
    },
    [setCookie],
  );

  useEffect(() => {
    if (!cookies.country && cookies.token) {
      onLogout();
    }
  }, [cookies.country, onLogout]);

  useEffect(() => {
    setAuthData({
      token: cookies.token,
      name: cookies.name,
      role: cookies.role,
      isMerchantAlias: cookies.isMerchantAlias === 'yes',
      country: cookies.country,
    });
  }, [
    cookies.token,
    cookies.name,
    cookies.role,
    cookies.isMerchantAlias,
    cookies.country,
  ]);

  const authDataValue = useMemo(
    () => ({
      ...authData,
      onLogin,
      setCookie,
      onLogout,
    }),
    [authData, onLogin, setCookie, onLogout],
  );

  return <AuthContext.Provider value={authDataValue} {...props} />;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
