import { format } from 'date-fns';

const Footer = () => (
  <div className="app-footer text-black-50">
    Cleo ©
    {' '}
    {format(new Date(), 'yyyy')}
  </div>
);

export default Footer;
