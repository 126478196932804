import axios from 'axios';
import { Cookies } from 'react-cookie';

import variables from '../config/variables';
import {
  KYC_SESSION_STATUSES,
  LOGIN_SESSION_STATUSES,
  PAYIN_SESSION_STATUSES,
  PAYOUT_SESSION_STATUSES,
  SESSION_TYPES,
} from '../constants/StatusTypes';

const baseUrl = `${variables.apiUrl}/dashboard`;
const baseAPIUrl = `${variables.apiUrl}/api`;

const cookie = new Cookies();
axios.interceptors.request.use(
  (config) => {
    const token = cookie.get('token');

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response
      && error.response.data
      && error.response.status
      && error.response.config
      && (error.response.config.url.includes('login-session-action')
        || error.response.config.url.includes('open-loop-payout-action'))
    ) {
      throw error.response;
    }

    if (
      error.response
      && error.response.status
      && error.response.status === 401
      && error.response.config
      && error.response.config.url !== '/dashboard/users/login'
      && error.response.config.url !== '/dashboard/merchants/change-password'
    ) {
      cookie.remove('token');
      cookie.remove('name');
      cookie.remove('role');
      document.location.href = '/';
    }
    throw error;
  },
);

// auth requests
/**
 * @param {string} login
 * @param {string} password
 */
export const login = async (login, password) => axios({
  method: 'post',
  url: `${baseUrl}/users/login`,
  data: {
    login,
    password,
  },
});

export const fetchCustomers = async (currentPage = 0, searchQuery, country) => axios({
  method: 'get',
  url: `${baseUrl}/customers/?page=${currentPage}${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${
    country ? `&country=${country}` : ''
  }`,
});

export const fetchMerchants = async (currentPage = 0, searchQuery, country) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/?page=${currentPage}${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${
    country ? `&country=${country}` : ''
  }`,
});

export const fetchMerchantsList = async (country) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/list?country=${country}`,
});

export const fetchCustomerUserData = async (customerId, country) => axios({
  method: 'get',
  url: `${baseUrl}/customers/${customerId}/?country=${country}`,
});

export const fetchPayinSessions = async (
  currentPage = 0,
  customerId,
  country,
  searchQuery,
  searchByRUT,
  searchByExternalKey,
  searchBySessionId,
  searchByAmount,
  showStatuses,
  filterSessionTypes,
  refundFilter,
  minDate,
  maxDate,
  merchantId,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payin?page=${currentPage}${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${
    searchByRUT && searchByRUT.length >= 3
      ? `&rut=${encodeURIComponent(searchByRUT)}`
      : ''
  }${
    searchByExternalKey && searchByExternalKey.length >= 3
      ? `&external_key=${encodeURIComponent(searchByExternalKey)}`
      : ''
  }${
    searchBySessionId && searchBySessionId.length >= 3
      ? `&session_id=${encodeURIComponent(searchBySessionId)}`
      : ''
  }${
    searchByAmount
      ? `&amount=${encodeURIComponent(searchByAmount)}`
      : ''
  }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
  }${
    showStatuses
      && showStatuses.length > 0
      && showStatuses.length !== PAYIN_SESSION_STATUSES.length
      ? `&statuses=${showStatuses.join(',')}`
      : ''
  }${
    filterSessionTypes
      && filterSessionTypes.length > 0
      && filterSessionTypes.length !== SESSION_TYPES.length
      ? `&types=${filterSessionTypes.join(',')}`
      : ''
  }${
    refundFilter === 'Refunded'
      ? '&is_refunded=true'
      : (refundFilter === 'Not refunded'
        ? '&is_refunded=false'
        : (refundFilter === 'None' ? '&is_refunded=null' : ''))
  }${
    merchantId ? `&merchant_id=${merchantId}` : ''
  }${
    customerId ? `&customer_id=${customerId}` : ''
  }${
    country ? `&country=${country}` : ''
  }`,
});

export const fetchPayinSession = async (sessionId, country) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payin/${sessionId}?country=${country}`,
});

export const fetchPayoutSession = async (sessionId, country) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payout/${sessionId}?country=${country}`,
});

export const fetchPayinSessionEvents = async (sessionId, country) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payin/${sessionId}/events?country=${country}`,
});

export const fetchPayoutSessionEvents = async (sessionId, country) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payout/${sessionId}/events?country=${country}`,
});

export const updateCustomer = async (customerId, customerData) => axios({
  method: 'put',
  url: `${baseUrl}/customers/${customerId}`,
  data: customerData,
});

export const submitMerchant = async (data) => axios({
  method: 'post',
  url: `${baseUrl}/merchants`,
  data,
});

export const updateMerchant = async (id, data) => axios({
  method: 'put',
  url: `${baseUrl}/merchants/${id}`,
  data,
});

export const fetchPayoutSessions = async (
  currentPage = 0,
  customerId,
  country,
  searchQuery,
  searchByRUT,
  searchByExternalKey,
  searchBySessionId,
  searchByAmount,
  showStatuses,
  searchByType,
  filterSessionTypes,
  minDate,
  maxDate,
  merchantId,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payout/?page=${currentPage}${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${
    searchByRUT && searchByRUT.length >= 3
      ? `&rut=${encodeURIComponent(searchByRUT)}`
      : ''
  }${
    searchByExternalKey && searchByExternalKey.length >= 3
      ? `&external_key=${encodeURIComponent(searchByExternalKey)}`
      : ''
  }${
    searchBySessionId && searchBySessionId.length >= 3
      ? `&session_id=${encodeURIComponent(searchBySessionId)}`
      : ''
  }${
    searchByAmount
      ? `&amount=${encodeURIComponent(searchByAmount)}`
      : ''
  }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
  }${
    showStatuses
      && showStatuses.length > 0
      && showStatuses.length !== PAYOUT_SESSION_STATUSES.length
      ? `&statuses=${showStatuses.join(',')}`
      : ''
  }${
    searchByType ? `&type=${searchByType}` : ''
  }${
    filterSessionTypes
      && filterSessionTypes.length > 0
      && filterSessionTypes.length !== SESSION_TYPES.length
      ? `&types=${filterSessionTypes.join(',')}`
      : ''
  }${
    merchantId ? `&merchant_id=${merchantId}` : ''
  }${
    customerId ? `&customer_id=${customerId}` : ''
  }${
    country ? `&country=${country}` : ''
  }`,
});

export const fetchSettlements = async (
  currentPage = 0,
  minDate,
  maxDate,
  merchantId,
  country,
) => axios({
  method: 'get',
  url: `${baseUrl}/settlements/?page=${currentPage}${
    minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''}${
    merchantId ? `&merchant_id=${merchantId}` : ''}${
    country ? `&country=${country}` : ''
  }`,
});

export const createSettlement = async (data) => axios({
  method: 'post',
  url: `${baseUrl}/settlements/create-settlement`,
  data,
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const updateSettlement = async (data, id) => axios({
  method: 'put',
  url: `${baseUrl}/settlements/update-settlement/${id}`,
  data,
  headers: { 'Content-Type': 'multipart/form-data' },
});

export const getSettlementFile = async (id) => axios({
  method: 'get',
  url: `${baseUrl}/settlements/attachment/${id}`,
  responseType: 'blob',
});

export const fetchReports = async (currentPage = 0, minDate, maxDate, merchantId, country) => axios({
  method: 'get',
  url: `${baseUrl}/reports/?page=${currentPage}${
    minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''
  }${maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''}${
    merchantId ? `&merchant_id=${merchantId}` : ''
  }${
    country ? `&country=${country}` : ''
  }`,
});

export const fetchOverallReports = async (minDate, maxDate, country) => axios({
  method: 'get',
  url: `${baseUrl}/reports/overall/?${
    minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''}${
    country ? `&country=${country}` : ''}`,
});

export const fetchSettlement = async (settlementId) => axios({
  method: 'get',
  url: `${baseUrl}/settlements/${settlementId}`,
});

export const fetchMerchantAliases = async (selectedMerchantId, country, currentPage = 0) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/aliases/?page=${currentPage}${
    selectedMerchantId ? `&merchant_id=${selectedMerchantId}` : ''}${
    country ? `&country=${country}` : ''}`,
});

export const createMerchantAlias = async (selectedMerchantId, data) => axios({
  method: 'post',
  url: `${baseUrl}/merchants/create-alias/?merchant_id=${selectedMerchantId}`,
  data,
});

export const updateMerchantAlias = async (merchanAliasId, data) => axios({
  method: 'put',
  url: `${baseUrl}/merchants/update-alias/${merchanAliasId}`,
  data,
});

export const fetchPayoutResolutions = async (currentPage = 0, minDate, maxDate) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payout-resolutions/?page=${currentPage}${
    minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''
  }${maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''}`,
});

export const runPayoutResolutionExport = async () => axios({
  method: 'post',
  url: `${baseUrl}/sessions/payout-resolutions`,
  responseType: 'blob',
});

export const setPayoutResolutionAsPaid = async (payoutResolutionId) => axios({
  method: 'post',
  url: `${baseUrl}/sessions/payout-resolutions/${payoutResolutionId}/set-paid`,
});

export const fetchPayoutResolutionDetails = async (payoutResolutionId) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payout-resolution/${payoutResolutionId}`,
});

export const payinCsvExport = async (
  searchQuery,
  searchByRUT,
  searchByExternalKey,
  searchBySessionId,
  searchByAmount,
  showStatuses,
  refundFilter,
  filterSessionTypes,
  minDate,
  maxDate,
  merchantId,
  country,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payin/export-csv?${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${
    searchByRUT && searchByRUT.length >= 3
      ? `&rut=${encodeURIComponent(searchByRUT)}`
      : ''
  }${
    searchByExternalKey && searchByExternalKey.length >= 3
      ? `&external_key=${encodeURIComponent(searchByExternalKey)}`
      : ''
  }${
    searchBySessionId && searchBySessionId.length >= 3
      ? `&session_id=${encodeURIComponent(searchBySessionId)}`
      : ''
  }${
    searchByAmount
      ? `&amount=${encodeURIComponent(searchByAmount)}`
      : ''
  }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
  }${
    showStatuses
    && showStatuses.length > 0
    && showStatuses.length !== PAYIN_SESSION_STATUSES.length
      ? `&statuses=${showStatuses.join(',')}`
      : ''
  }${
    filterSessionTypes
    && filterSessionTypes.length > 0
    && filterSessionTypes.length !== SESSION_TYPES.length
      ? `&types=${filterSessionTypes.join(',')}`
      : ''
  }${
    refundFilter === 'Refunded'
      ? '&is_refunded=true'
      : (refundFilter === 'Not refunded'
        ? '&is_refunded=false'
        : (refundFilter === 'None' ? '&is_refunded=null' : ''))
  }${
    merchantId ? `&merchant_id=${merchantId}` : ''
  }${
    country ? `&country=${country}` : ''
  }`,
  responseType: 'blob',
});

export const payoutCsvExport = async (
  searchQuery,
  searchByRUT,
  searchByExternalKey,
  searchBySessionId,
  searchByAmount,
  showStatuses,
  searchByType,
  filterSessionTypes,
  minDate,
  maxDate,
  merchantId,
  country,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payout/export-csv?${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${
    searchByRUT && searchByRUT.length >= 3
      ? `&rut=${encodeURIComponent(searchByRUT)}`
      : ''
  }${
    searchByExternalKey && searchByExternalKey.length >= 3
      ? `&external_key=${encodeURIComponent(searchByExternalKey)}`
      : ''
  }${
    searchBySessionId && searchBySessionId.length >= 3
      ? `&session_id=${encodeURIComponent(searchBySessionId)}`
      : ''
  }${
    searchByAmount
      ? `&amount=${encodeURIComponent(searchByAmount)}`
      : ''
  }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
  }${
    showStatuses
    && showStatuses.length > 0
    && showStatuses.length !== PAYOUT_SESSION_STATUSES.length
      ? `&statuses=${showStatuses.join(',')}`
      : ''
  }${
    searchByType ? `&type=${searchByType}` : ''
  }${
    filterSessionTypes
    && filterSessionTypes.length > 0
    && filterSessionTypes.length !== SESSION_TYPES.length
      ? `&types=${filterSessionTypes.join(',')}`
      : ''
  }${
    merchantId ? `&merchant_id=${merchantId}` : ''
  }${
    country ? `&country=${country}` : ''
  }`,
  responseType: 'blob',
});

export const fetchMerchantBalance = async (selectedMerchantId) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/merchant-balance${
    selectedMerchantId !== 'All' ? `?merchant_id=${selectedMerchantId}` : ''}`,
});

export const fetchMerchantTopUps = async (selectedMerchantId, country, currentPage = 0) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/top-ups/?page=${currentPage}${
    selectedMerchantId !== 'All' ? `&merchant_id=${selectedMerchantId}` : ''}${
    country ? `&country=${country}` : ''}`,
});

export const createMerchantTopUp = async (selectedMerchantId, data) => axios({
  method: 'post',
  url: `${baseUrl}/merchants/create-top-up/?merchant_id=${selectedMerchantId}`,
  data,
});

export const getLoginSessionState = async (sessionId) => axios({
  method: 'get',
  url: `${baseAPIUrl}/v1/paynplay/login-session-action/${sessionId}/state`,
});

export const bankOnboarding = async (sessionId, payload) => axios({
  method: 'post',
  url: `${baseAPIUrl}/v1/paynplay/login-session-action/${sessionId}/bank-onboarding`,
  data: { ...payload },
});

export const backHandler = async (sessionId) => axios({
  method: 'post',
  url: `${baseAPIUrl}/v1/paynplay/login-session-action/${sessionId}/abort`,
});

export const fetchLoginSessions = async (
  currentPage = 0,
  searchQuery,
  showStatuses,
  minDate,
  maxDate,
  merchantId,
  customerId,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/login?page=${currentPage}${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
  }${
    showStatuses
      && showStatuses.length > 0
      && showStatuses.length !== LOGIN_SESSION_STATUSES.length
      ? `&statuses=${showStatuses.join(',')}`
      : ''
  }${merchantId ? `&merchant_id=${merchantId}` : ''}${
    customerId ? `&customer_id=${customerId}` : ''
  }`,
});

export const loginCsvExport = async (
  searchQuery,
  showStatuses,
  minDate,
  maxDate,
  merchantId,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/login/export-csv?${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
  }${
    showStatuses
    && showStatuses.length > 0
    && showStatuses.length !== LOGIN_SESSION_STATUSES.length
      ? `&statuses=${showStatuses.join(',')}`
      : ''
  }${merchantId ? `&merchant_id=${merchantId}` : ''}`,
  responseType: 'blob',
});

export const fetchLoginSession = async (sessionId) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/login/${sessionId}`,
});

export const fetchLoginSessionEvents = async (sessionId) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/login/${sessionId}/events`,
});

export const fetchKycSessions = async (
  currentPage = 0,
  searchQuery,
  showStatuses,
  minDate,
  maxDate,
  merchantId,
  customerId,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/kyc?page=${currentPage}${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
  }${
    showStatuses
      && showStatuses.length > 0
      && showStatuses.length !== KYC_SESSION_STATUSES.length
      ? `&statuses=${showStatuses.join(',')}`
      : ''
  }${merchantId ? `&merchant_id=${merchantId}` : ''}${
    customerId ? `&customer_id=${customerId}` : ''
  }`,
});

export const fetchKycSession = async (sessionId) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/kyc/${sessionId}`,
});

export const fetchKycSessionEvents = async (sessionId) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/kyc/${sessionId}/events`,
});

export const fetchPayinPaymentSession = async (sessionId, country) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payin/${sessionId}/payin-payment-session?country=${country}`,
});

export const fetchPayinPaymentLogJson = async (paymentLogId, paymentType, country) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payin-payment-log-json?payment_log_id=${paymentLogId}&payment_type=${paymentType}&country=${country}`,
});

export const fetchApiRequest = async (
  currentPage = 0,
  searchByRUT,
  searchByExternalKey,
  searchBySessionId,
  minDate,
  maxDate,
  merchantId,
  country,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/api-requests?page=${currentPage}${
    searchByRUT && searchByRUT.length >= 3
      ? `&rut=${encodeURIComponent(searchByRUT)}`
      : ''
  }${
    searchByExternalKey && searchByExternalKey.length >= 3
      ? `&external_key=${encodeURIComponent(searchByExternalKey)}`
      : ''
  }${
    searchBySessionId && searchBySessionId.length >= 3
      ? `&session_id=${encodeURIComponent(searchBySessionId)}`
      : ''
  }${
    minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''}${
    merchantId !== 'All' ? `&merchant_id=${merchantId}` : ''}${
    country ? `&country=${country}` : ''}`,
});

export const cancelPayout = async (payoutId) => axios({
  method: 'post',
  url: `${baseUrl}/sessions/payout/${payoutId}/cancel`,
});

export const claveUnicaDebts = async (sessionId, payload) => axios({
  method: 'post',
  url: `${baseAPIUrl}/v1/paynplay/login-session-action/${sessionId}/clave-unica-debts`,
  data: { ...payload },
});

export const chooseLoginType = async (sessionId, payload) => axios({
  method: 'post',
  url: `${baseAPIUrl}/v1/paynplay/login-session-action/${sessionId}/choose-login-type`,
  data: { ...payload },
});

export const fetchMerchantBalanceLog = async (
  minDate,
  maxDate,
  merchantId,
) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/merchant-balance-log?${
    minDate ? `min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''}${
    merchantId !== 'All' ? `&merchant_id=${merchantId}` : ''}`,
});

export const merchantBalanceLogCsvExport = async (
  minDate,
  maxDate,
  merchantId,
) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/merchant-balance-export-csv?${
    minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''}${
    merchantId ? `&merchant_id=${merchantId}` : ''}`,
  responseType: 'blob',
});

export const changePassword = async (payload) => axios({
  method: 'post',
  url: `${baseUrl}/merchants/change-password`,
  data: { ...payload },
});

export const fetchMerchantBankFees = async (
  merchantId,
) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/merchant-bank-fees?merchant_id=${merchantId}`,
});

export const updateMerchantBankFees = async (merchantId, data) => axios({
  method: 'post',
  url: `${baseUrl}/merchants/merchant-bank-fees?merchant_id=${merchantId}`,
  data,
});

export const payoutGetPdfReceipt = async (payoutId) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payout/get-pdf-receipt/${payoutId}`,
  responseType: 'blob',
});

export const payinGetPdfReceipt = async (payinId) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payin/get-pdf-receipt/${payinId}`,
  responseType: 'blob',
});

export const fetchPayoutPaymentSession = async (sessionId, country) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payout/${sessionId}/payout-payment-session?country=${country}`,
});

export const fetchPayoutPaymentCallbackJson = async (
  paymentCallbackId,
  paymentCallbackType,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/payout/payment-callback-json?callback_id=${paymentCallbackId}&payment_type=${paymentCallbackType}`,
});

export const fetchBankProviders = async (country) => axios({
  method: 'get',
  url: `${baseUrl}/settings/bank-providers?country=${country}`,
});

export const updateBankProviders = async (data) => axios({
  method: 'put',
  url: `${baseUrl}/settings/bank-providers`,
  data,
});

export const fetchPromotions = async (
  merchantId,
  minDate,
  maxDate,
  status,
  country,
  currentPage = 0,
) => axios({
  method: 'get',
  url: `${baseUrl}/promotions?page=${currentPage}${
    minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''}${
    merchantId ? `&merchant_id=${merchantId}` : ''}${
    status ? `&status=${status}` : ''}${
    country ? `&country=${country}` : ''}`,
});

export const createPromotion = async (data) => axios({
  method: 'post',
  url: `${baseUrl}/promotions/create-promotion`,
  data,
});

export const changePromotionStatus = async (data) => axios({
  method: 'post',
  url: `${baseUrl}/promotions/change-status`,
  data,
});

export const changePromotionEndDate = async (data) => axios({
  method: 'post',
  url: `${baseUrl}/promotions/change-end-date`,
  data,
});

export const fetchPromotion = async (promotionId) => axios({
  method: 'get',
  url: `${baseUrl}/promotions/${promotionId}`,
});

export const fetchPromotionPayin = async (promotionId) => axios({
  method: 'get',
  url: `${baseUrl}/promotions/${promotionId}/payin-sessions`,
});

export const fetchMerchantPayinBankProviders = async (
  merchantId,
) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/payin-bank-providers?merchant_id=${merchantId}`,
});

export const updateMerchantPayinBankProviders = async (merchantId, data) => axios({
  method: 'post',
  url: `${baseUrl}/merchants/payin-bank-providers?merchant_id=${merchantId}`,
  data,
});

export const createRefund = async (payinId, data) => axios({
  method: 'post',
  url: `${baseUrl}/sessions/refund/${payinId}/create-refund`,
  data,
});

export const createManualRefund = async (payinId, data) => axios({
  method: 'post',
  url: `${baseUrl}/sessions/refund/${payinId}/create-manual-refund`,
  data,
});

export const fetchMerchantCustomers = async (
  searchQuery,
  selectedMerchantId,
  country,
  currentPage = 0,
) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/customers/?page=${currentPage}${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${
    country ? `&country=${country}` : ''
  }${
    selectedMerchantId ? `&merchant_id=${selectedMerchantId}` : ''}`,
});

export const setMerchantCustomerUnblock = async (data) => axios({
  method: 'post',
  url: `${baseUrl}/merchants/customers-unblock`,
  data,
});

export const fetchRefundSessions = async (
  currentPage = 0,
  searchQuery,
  searchByRUT,
  searchByExternalKey,
  searchBySessionId,
  searchByAmount,
  showStatuses,
  minDate,
  maxDate,
  merchantId,
  customerId,
  country,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/refund/?page=${currentPage}${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${
    searchByRUT && searchByRUT.length >= 3
      ? `&rut=${encodeURIComponent(searchByRUT)}`
      : ''
  }${
    searchByExternalKey && searchByExternalKey.length >= 3
      ? `&external_key=${encodeURIComponent(searchByExternalKey)}`
      : ''
  }${
    searchBySessionId && searchBySessionId.length >= 3
      ? `&session_id=${encodeURIComponent(searchBySessionId)}`
      : ''
  }${
    searchByAmount
      ? `&amount=${encodeURIComponent(searchByAmount)}`
      : ''
  }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
  }${
    showStatuses
      && showStatuses.length > 0
      && showStatuses.length !== PAYOUT_SESSION_STATUSES.length
      ? `&statuses=${showStatuses.join(',')}`
      : ''
  }${
    merchantId ? `&merchant_id=${merchantId}` : ''
  }${
    customerId ? `&customer_id=${customerId}` : ''
  }${
    country ? `&country=${country}` : ''
  }`,
});

export const refundCsvExport = async (
  searchQuery,
  searchByRUT,
  searchByExternalKey,
  searchBySessionId,
  searchByAmount,
  showStatuses,
  minDate,
  maxDate,
  merchantId,
  country,
) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/refund/export-csv?${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${
    searchByRUT && searchByRUT.length >= 3
      ? `&rut=${encodeURIComponent(searchByRUT)}`
      : ''
  }${
    searchByExternalKey && searchByExternalKey.length >= 3
      ? `&external_key=${encodeURIComponent(searchByExternalKey)}`
      : ''
  }${
    searchBySessionId && searchBySessionId.length >= 3
      ? `&session_id=${encodeURIComponent(searchBySessionId)}`
      : ''
  }${
    searchByAmount
      ? `&amount=${encodeURIComponent(searchByAmount)}`
      : ''
  }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
    maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
  }${
    showStatuses
    && showStatuses.length > 0
    && showStatuses.length !== PAYOUT_SESSION_STATUSES.length
      ? `&statuses=${showStatuses.join(',')}`
      : ''
  }${
    merchantId ? `&merchant_id=${merchantId}` : ''
  }${
    country ? `&country=${country}` : ''
  }`,
  responseType: 'blob',
});

export const fetchRefundSession = async (sessionId, country) => axios({
  method: 'get',
  url: `${baseUrl}/sessions/refund/${sessionId}?country=${country}`,
});

export const fetchCompanies = async (currentPage = 0, searchQuery, country) => axios({
  method: 'get',
  url: `${baseUrl}/companies/?page=${currentPage}${
    searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
  }${
    country ? `&country=${country}` : ''
  }`,
});

export const submitCompany = async (data) => axios({
  method: 'post',
  url: `${baseUrl}/companies`,
  data,
});

export const updateCompany = async (id, data) => axios({
  method: 'put',
  url: `${baseUrl}/companies/${id}`,
  data,
});

export const fetchCompaniesList = async (country) => axios({
  method: 'get',
  url: `${baseUrl}/companies/list?country=${country}`,
});

export const deleteCompany = async (id) => axios({
  method: 'delete',
  url: `${baseUrl}/companies/${id}`,
});

export const fetchMerchantData = async (merchantId, country) => axios({
  method: 'get',
  url: `${baseUrl}/merchants/${merchantId}?country=${country}`,
});

export const fetchPayinPercentageFees = async (companyId) => axios({
  method: 'get',
  url: `${baseUrl}/companies/payin-percentage-fees/${companyId}`,
});

export const linkCustomerToPayin = async (payinId, data) => axios({
  method: 'post',
  url: `${baseUrl}/customers/link-customer-to-payin/${payinId}`,
  data,
});

export const getFintocChileSessionInfo = async (sessionId) => axios({
  method: 'get',
  url: `${variables.apiUrl}/fintoc-chile/session-info?session_id=${sessionId}`,
});

export const fetchCountries = async (id) => axios({
  method: 'get',
  url: `${baseUrl}/settings/country/${id}`,
});

export const updateCountries = async (id, data) => axios({
  method: 'put',
  url: `${baseUrl}/settings/country/${id}`,
  data,
});

export const setPayinSuccessStatus = async (payinId, data) => axios({
  method: 'post',
  url: `${baseUrl}/sessions/payin/${payinId}/set-success-status`,
  data,
});

export const resendPayinCallback = async (payinId) => axios({
  method: 'post',
  url: `${baseUrl}/sessions/payin/${payinId}/resend-payin-callback`,
});

export const getOpenLoopPayoutState = async (sessionId) => axios({
  method: 'get',
  url: `${baseAPIUrl}/v1/open-loop-payout-action/${sessionId}/state`,
});

export const openLoopPayoutAccount = async (sessionId, payload) => axios({
  method: 'post',
  url: `${baseAPIUrl}/v1/open-loop-payout-action/${sessionId}/validate-account`,
  data: { ...payload },
});

export const openLoopPayoutBankId = async (sessionId, payload) => axios({
  method: 'post',
  url: `${baseAPIUrl}/v1/open-loop-payout-action/${sessionId}/bank-id`,
  data: { ...payload },
});

export const openLoopPayoutChooseValidationType = async (sessionId, payoload) => axios({
  method: 'post',
  url: `${baseAPIUrl}/v1/open-loop-payout-action/${sessionId}/choose-validation-type`,
  data: { ...payoload },
});

export const openLoopPayoutAbortSession = async (sessionId) => axios({
  method: 'post',
  url: `${baseAPIUrl}/v1/open-loop-payout-action/${sessionId}/abort`,
});

export const openLoopPayoutChooseBankAccount = async (sessionId, payload) => axios({
  method: 'post',
  url: `${baseAPIUrl}/v1/open-loop-payout-action/${sessionId}/choose-account`,
  data: { ...payload },
});

export const updateMerchantBalance = async (merchantId) => axios({
  method: 'put',
  url: `${baseUrl}/merchants/${merchantId}/update-balance`,
});
