import Card from '@material-ui/core/esm/Card';
import CardContent from '@material-ui/core/esm/CardContent';

const ExampleWrapperSimple = ({ sectionHeading, children }) => (
  <Card className="card-box mb-4-spacing overflow-visible">
    <div className="card-header">
      <div className="card-header--title font-size-md font-weight-bold py-2">
        {sectionHeading}
      </div>
    </div>
    <CardContent className="p-3">{children}</CardContent>
  </Card>
);

export default ExampleWrapperSimple;
