import clsx from "clsx";
import { connect } from "react-redux";
import {useLocation} from 'react-router-dom'

import { Header, Sidebar } from "../../layout-components";
import { resetMerchantFormData } from "../../reducers/ThemeOptions";

const LeftSidebar = function(props) {
  const {
    children,
    sidebarToggle,
    sidebarFixed,
  } = props;

  return (
    <div className={clsx("app-wrapper")}>
      <Header />
      <div
        className={clsx("app-main", {
          "app-main-sidebar-static": !sidebarFixed,
        })}
      >
        <Sidebar />
        <div
          className={clsx("app-content app-content-sidebar-fixed", {
            "app-content-sidebar-collapsed": sidebarToggle,
          })}
        >
          <div className="app-content--inner">
            <div className="app-content--inner__wrapper">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
});

const mapDispatchToProps = (dispatch) => ({
  resetMerchantFormData: () => dispatch(resetMerchantFormData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
