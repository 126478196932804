import AccountBalance from '@material-ui/icons/AccountBalance';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddBox from '@material-ui/icons/AddBox';
import AdjustIcon from '@material-ui/icons/Adjust';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import BusinessIcon from '@material-ui/icons/Business';
import ArchiveIcon from '@material-ui/icons/esm/Archive';
import DashboardTwoToneIcon from '@material-ui/icons/esm/DashboardTwoTone';
import PersonIcon from '@material-ui/icons/esm/PersonOutlined';
import StorefrontIcon from '@material-ui/icons/esm/Storefront';
import WorkIcon from '@material-ui/icons/esm/Work';
import EventNote from '@material-ui/icons/EventNote';
import FaceIcon from '@material-ui/icons/Face';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LocalOffer from '@material-ui/icons/LocalOffer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SettingsIcon from '@material-ui/icons/Settings';
import StoreIcon from '@material-ui/icons/Store';
import VpnKey from '@material-ui/icons/VpnKey';

import { COUNTRIES, COUNTRIES_DICT } from '../../constants/Countries';
import { useAuthContext } from '../../providers/AuthProvider';
import SidebarMenu from '../SidebarMenu';

const navItems = [
  {
    label: 'Menu',
    icon: DashboardTwoToneIcon,
    content: [
      {
        label: 'Customers',
        to: '/customers',
        icon: PersonIcon,
        roles: ['ADMIN', 'MERCHANT'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Payins',
        to: '/payin',
        icon: MonetizationOnIcon,
        roles: ['ADMIN', 'MERCHANT'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Payouts',
        to: '/payout',
        icon: WorkIcon,
        roles: ['ADMIN', 'MERCHANT'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Payout resolutions',
        to: '/payout-resolutions',
        icon: ArchiveIcon,
        roles: ['ADMIN'],
        countries: [COUNTRIES.CL],
      },
      {
        label: 'Refunds',
        to: '/refunds',
        icon: AssignmentReturnIcon,
        roles: ['ADMIN', 'MERCHANT'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Login sessions',
        to: '/login-session',
        icon: AccountCircle,
        roles: ['ADMIN'],
        countries: [COUNTRIES.CL],
      },
      {
        label: 'Kyc sessions',
        to: '/kyc-session',
        icon: FaceIcon,
        roles: ['ADMIN'],
        countries: [COUNTRIES.CL],
      },
      {
        label: 'Companies',
        to: '/companies',
        icon: BusinessIcon,
        roles: ['ADMIN'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Merchants',
        to: '/merchants',
        icon: StorefrontIcon,
        roles: ['ADMIN'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Merchant aliases',
        to: '/merchant-aliases',
        icon: StoreIcon,
        roles: ['ADMIN', 'MERCHANT'],
        isMerchantAliasDenied: true,
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Merchant top ups',
        to: '/merchant-top-ups',
        icon: AddBox,
        roles: ['ADMIN', 'MERCHANT'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Merchant balance',
        to: '/merchant-balance',
        icon: AccountBalance,
        roles: ['ADMIN', 'MERCHANT'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Merchant customers',
        to: '/merchant-customers',
        icon: HomeWorkIcon,
        roles: ['ADMIN', 'MERCHANT'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Promotions',
        to: '/promotions',
        icon: LocalOffer,
        roles: ['ADMIN'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Settlements',
        to: '/settlements',
        icon: AdjustIcon,
        roles: ['ADMIN', 'MERCHANT'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Reports',
        to: '/reports',
        icon: FormatListNumberedIcon,
        roles: ['ADMIN', 'MERCHANT'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'API requests',
        to: '/api-requests',
        icon: EventNote,
        roles: ['ADMIN'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Change password',
        to: '/change-password',
        icon: VpnKey,
        roles: ['ADMIN', 'MERCHANT'],
        countries: COUNTRIES_DICT,
      },
      {
        label: 'Settings',
        to: '/settings',
        icon: SettingsIcon,
        roles: ['ADMIN'],
        countries: COUNTRIES_DICT,
      },
    ],
  },
];

const hasRights = (item, role, isMerchantAlias, country) => {
  let itemAlowed = true;
  if (role === 'MERCHANT' && isMerchantAlias) {
    itemAlowed = !item.isMerchantAliasDenied;
  }

  return item.roles.includes(role) && itemAlowed && item.countries.includes(country);
};

const NavItems = () => {
  const { role, isMerchantAlias, country } = useAuthContext();

  const navItemsFiltered = navItems.map((nav) => ({
    ...nav,
    content: nav.content.filter((item) => hasRights(item, role, isMerchantAlias, country)),
  }));

  return (
    <SidebarMenu component="div" pages={navItemsFiltered} title="" />
  );
};

export default NavItems;
