// Sidebar
export const SET_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_SIDEBAR_SHADOW';
export const SET_SIDEBAR_TOGGLE_MOBILE = 'THEME_OPTIONS/SET_SIDEBAR_TOGGLE_MOBILE';
export const SET_SIDEBAR_FIXED = 'THEME_OPTIONS/SET_SIDEBAR_FIXED';
export const SET_SIDEBAR_FOOTER = 'THEME_OPTIONS/SET_SIDEBAR_FOOTER';
export const SET_SIDEBAR_TOGGLE = 'THEME_OPTIONS/SET_SIDEBAR_TOGGLE';
export const SET_SIDEBAR_USERBOX = 'THEME_OPTIONS/SET_SIDEBAR_USERBOX';
export const SET_SIDEBAR_HOVER = 'THEME_OPTIONS/SET_SIDEBAR_HOVER';
export const SET_MERCHANT_FORM_DATA = 'THEME_OPTIONS/SET_MERCHANT_FORM_DATA';
export const RESET_MERCHANT_FORM_DATA = 'THEME_OPTIONS/RESET_MERCHANT_FORM_DATA';
export const SET_OPERATOR_FORM_DATA = 'THEME_OPTIONS/SET_OPERATOR_FORM_DATA';
export const RESET_OPERATOR_FORM_DATA = 'THEME_OPTIONS/RESET_OPERATOR_FORM_DATA';

export const setSidebarToggleMobile = (sidebarToggleMobile) => ({
  type: SET_SIDEBAR_TOGGLE_MOBILE,
  sidebarToggleMobile,
});
export const setSidebarToggle = (sidebarToggle) => ({
  type: SET_SIDEBAR_TOGGLE,
  sidebarToggle,
});
export const setSidebarHover = (sidebarHover) => ({
  type: SET_SIDEBAR_HOVER,
  sidebarHover,
});
export const setMerchantFormData = (payload) => ({
  type: SET_MERCHANT_FORM_DATA,
  payload,
});
export const resetMerchantFormData = (payload) => ({
  type: RESET_MERCHANT_FORM_DATA,
  payload,
});
export const setOperatorFormData = (payload) => ({
  type: SET_OPERATOR_FORM_DATA,
  payload,
});

// Page title
export default function reducer(
  state = {
    sidebarToggleMobile: false,
    sidebarToggle: false,
    sidebarHover: false,
  },
  action,
) {
  switch (action.type) {
    // Sidebar
    case SET_SIDEBAR_TOGGLE_MOBILE:
      return {
        ...state,
        sidebarToggleMobile: action.sidebarToggleMobile,
      };
    case SET_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: action.sidebarToggle,
      };
    case SET_SIDEBAR_HOVER:
      return {
        ...state,
        sidebarHover: action.sidebarHover,
      };
    default:
      break;
  }

  return state;
}
