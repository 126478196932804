import { List, Typography } from '@material-ui/core';
import { matchPath, useLocation } from 'react-router-dom';

import SidebarMenuListItem from './SidebarMenuListItem';

const reduceChildRoutes = ({
  location, items, page, depth,
}) => {
  const open = false;

  if (page.content) {
    if (page.content.length > 0) {
      for (let i = 0; i < page.content.length; i++) {
        const subPage = page.content[i];
        // if (
        //   subPage.to &&
        //   matchPath(location.pathname, {
        //     path: subPage.to,
        //     exact: false,
        //   })
        // ) {
        //   open = true;
        // }
      }
    }

    items.push(
      <SidebarMenuListItem
        key={page.label}
        depth={depth}
        disabled={page.disabled}
        icon={page.icon}
        label={page.badge}
        open={Boolean(open)}
        title={page.label}
      >
        <div className="sidebar-menu-children py-2">
          <SidebarMenuList
            depth={depth + 1}
            location={location}
            pages={page.content}
          />
        </div>
      </SidebarMenuListItem>,
    );
  } else {
    items.push(
      <SidebarMenuListItem
        key={page.label}
        depth={depth}
        disabled={page.disabled}
        href={page.to}
        icon={page.icon}
        label={page.badge}
        title={page.label}
      />,
    );
  }

  return items;
};

const SidebarMenuList = function ({ pages, ...props }) {
  return (
    <List className="p-0">
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...props }),
        [],
      )}
    </List>
  );
};

const SidebarMenu = function ({
  title,
  pages,
  className,
  Component = 'nav',
  ...props
}) {
  const location = useLocation();

  return (
    <Component {...props} className={className}>
      {title && (
        <Typography className="app-sidebar-heading">{title}</Typography>
      )}
      <SidebarMenuList depth={0} location={location} pages={pages} />
    </Component>
  );
};

export default SidebarMenu;
