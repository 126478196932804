import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@material-ui/core/esm/Badge';
import Box from '@material-ui/core/esm/Box';
import IconButton from '@material-ui/core/esm/IconButton';
import Typography from '@material-ui/core/esm/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/esm/ExitToApp';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AmountFormat from '../../components/Format/AmountFormat';
import { fetchMerchantBalance } from '../../providers/api';
import { useAuthContext } from '../../providers/AuthProvider';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'block',
    marginLeft: '10px',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

/**
 *
 */
export default function HeaderUserbox() {
  const location = useLocation();
  const classes = useStyles();
  const { onLogout, name, role } = useAuthContext();

  const [merchantBalance, setMerchantBalance] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await fetchMerchantBalance();
        setMerchantBalance(data);
      } catch (error) {
        console.log('Error while fetch merchant balance', error);
      }
    };

    if (role === 'MERCHANT') {
      fetch();
    }
  }, [setMerchantBalance, role, location]);

  return (
    <>
      <span className="text-capitalize px-3 text-left d-flex align-items-center">
        <Box className="pr-1">
          <StyledBadge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            overlap="circular"
            variant="dot"
          >
            <FontAwesomeIcon color="white" icon={faUser} size="2x" />
          </StyledBadge>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography noWrap className={classes.title} variant="h5">
            {name}
          </Typography>
          {role === 'MERCHANT'
            && (
            <Typography className={classes.title} variant="text">
              <span className="mr-2">Balance:</span>
              <span>
                <AmountFormat value={merchantBalance} />
              </span>
            </Typography>
            )}
        </Box>
      </span>
      <IconButton
        aria-label="Logout"
        color="inherit"
        onClick={() => {
          setMerchantBalance(0);
          onLogout();
        }}
      >
        <ExitToAppIcon />
      </IconButton>
    </>
  );
}
