import ThemeProvider from '@material-ui/styles/ThemeProvider';
import {
  lazy, Suspense, useCallback, useEffect, useState,
} from 'react';
import {
  Navigate, Route, Routes, useLocation,
} from 'react-router-dom';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

import MinimalLayoutRoutes from './constants/MinimalLayoutRoutes';
// Layout Blueprints
import { LeftSidebar, MinimalLayout } from './layout-blueprints';
import { useAuthContext } from './providers/AuthProvider';
import MuiTheme from './theme';

const Error404 = lazy(() => import('./pages/Error404'));

const Login = lazy(() => import('./pages/Login'));

const LoginSessionWrapper = lazy(() => import('./login-session/LoginSessionWrapper'));

const Companies = lazy(() => import('./pages/Companies'));
const Merchants = lazy(() => import('./pages/Merchants'));
const Merchant = lazy(() => import('./pages/Merchant'));
const MerchantAliases = lazy(() => import('./pages/MerchantAliases'));
const MerchantTopUps = lazy(() => import('./pages/MerchantTopUps'));
const MerchantBalance = lazy(() => import('./pages/MerchantBalance'));
const MerchantCustomers = lazy(() => import('./pages/MerchantCustomers'));
const Promotions = lazy(() => import('./pages/Promotions'));
const Promotion = lazy(() => import('./pages/Promotion'));

const PayinSessions = lazy(() => import('./pages/Payins'));
const PayinSession = lazy(() => import('./pages/Payin'));
const PayoutSessions = lazy(() => import('./pages/Payouts'));
const PayoutSession = lazy(() => import('./pages/Payout'));
const RefundSessions = lazy(() => import('./pages/Refunds'));
const RefundSession = lazy(() => import('./pages/Refund'));
const LoginSessions = lazy(() => import('./pages/LoginSessions'));
const LoginSession = lazy(() => import('./pages/LoginSession'));
const KycSessions = lazy(() => import('./pages/KycSessions'));
const KycSession = lazy(() => import('./pages/KycSession'));

const PayoutResolutions = lazy(() => import('./pages/PayoutResolutions'));
const PayoutResolution = lazy(() => import('./pages/PayoutResolution'));

const Customers = lazy(() => import('./pages/Customers'));
const Customer = lazy(() => import('./pages/Customer'));

const Settlements = lazy(() => import('./pages/Settlements'));

const Reports = lazy(() => import('./pages/Reports'));

const ApiRequests = lazy(() => import('./pages/ApiRequests'));

const ChangePassword = lazy(() => import('./pages/ChangePassword'));

const Settings = lazy(() => import('./pages/Settings'));

const FintocWidget = lazy(() => import('./fintoc-widget'));

const OpenLoopPayoutWrapper = lazy(() => import('./open-loop-payout/OpenLoopPayoutWrapper'));

const AppRoutes = () => {
  const location = useLocation();
  const authData = useAuthContext();
  const isUserLogged = !!authData.token;
  const { role, isMerchantAlias } = useAuthContext();
  const isUserAdmin = role === 'ADMIN';
  const isMinimalLayout = MinimalLayoutRoutes.some(((route) => location.pathname.includes(route)));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [authData.token]);

  const PrivateRoute = useCallback(
    ({ children }) => (isUserLogged ? children : <Navigate to="/login" />),
    [isUserLogged],
  );

  const AdminRoute = useCallback(
    ({ children }) => (!isUserLogged ? (
      <Navigate to="/login" />
    ) : (!isUserAdmin ? (
      <Navigate to="/" />
    ) : (
      children
    ))),
    [isUserAdmin, isUserLogged],
  );

  const SuspenseLoading = useCallback(
    () => (
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader loading color="#5383ff" />
        </div>

        <div className="text-muted font-size-xl text-center pt-3">
          Please wait a little while the application is loading
        </div>
      </div>
    ),
    [],
  );

  let Layout;
  if (isMinimalLayout) {
    Layout = MinimalLayout;
  } else {
    Layout = isUserLogged ? LeftSidebar : MinimalLayout;
  }

  if (loading) {
    return <SuspenseLoading />;
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <Suspense fallback={<SuspenseLoading />}>
        <Layout>
          <Routes key={location.pathname} location={location}>
            <Route element={<LoginSessionWrapper />} exact="true" path="/v1/login/:id" />
            {(isUserLogged && isUserAdmin && (
              <Route
                element={<Navigate to="/payin" />}
                exact="true"
                path="/login"
              />
            ))
              || (isUserLogged && (
                <Route
                  element={<Navigate to="/payin" />}
                  exact="true"
                  path="/login"
                />
              ))}
            {(isUserLogged && isUserAdmin && (
              <Route element={<Navigate to="/payin" />} exact="true" path="/" />
            ))
              || (isUserLogged && (
                <Route
                  element={<Navigate to="/payin" />}
                  exact="true"
                  path="/"
                />
              ))}
            <Route element={<Login />} path="/login" />
            <Route
              element={(
                <AdminRoute>
                  <Companies />
                </AdminRoute>
              )}
              exact="true"
              path="/companies"
            />
            <Route
              element={(
                <AdminRoute>
                  <Merchants />
                </AdminRoute>
              )}
              exact="true"
              path="/merchants"
            />
            <Route
              element={(
                <AdminRoute>
                  <Merchant />
                </AdminRoute>
              )}
              exact="true"
              path="/merchant/:id"
            />
            {!isMerchantAlias && (
              <Route
                element={(
                  <PrivateRoute>
                    <MerchantAliases />
                  </PrivateRoute>
                )}
                exact="true"
                path="/merchant-aliases"
              />
            )}
            <Route
              element={(
                <PrivateRoute>
                  <MerchantTopUps />
                </PrivateRoute>
              )}
              exact="true"
              path="/merchant-top-ups"
            />
            <Route
              element={(
                <PrivateRoute>
                  <MerchantBalance />
                </PrivateRoute>
              )}
              exact="true"
              path="/merchant-balance"
            />
            <Route
              element={(
                <PrivateRoute>
                  <MerchantCustomers />
                </PrivateRoute>
              )}
              exact="true"
              path="/merchant-customers"
            />
            <Route
              element={(
                <AdminRoute>
                  <Promotions />
                </AdminRoute>
              )}
              exact="true"
              path="/promotions"
            />
            <Route
              element={(
                <AdminRoute>
                  <Promotion />
                </AdminRoute>
              )}
              exact="true"
              path="/promotion/:id"
            />
            <Route
              element={(
                <PrivateRoute>
                  <PayoutSessions />
                </PrivateRoute>
              )}
              exact="true"
              path="/payout"
            />
            <Route
              element={(
                <PrivateRoute>
                  <PayoutResolutions />
                </PrivateRoute>
              )}
              exact="true"
              path="/payout-resolutions"
            />
            <Route
              element={(
                <PrivateRoute>
                  <RefundSessions />
                </PrivateRoute>
              )}
              exact="true"
              path="/refunds"
            />
            <Route
              element={(
                <PrivateRoute>
                  <PayinSessions />
                </PrivateRoute>
              )}
              exact="true"
              path="/payin"
            />
            <Route
              element={(
                <PrivateRoute>
                  <PayinSession />
                </PrivateRoute>
              )}
              exact="true"
              path="/payin/:id"
            />
            <Route
              element={(
                <PrivateRoute>
                  <PayoutSession />
                </PrivateRoute>
              )}
              exact="true"
              path="/payout/:id"
            />
            <Route
              element={(
                <PrivateRoute>
                  <RefundSession />
                </PrivateRoute>
              )}
              exact="true"
              path="/refund/:id"
            />
            <Route
              element={(
                <PrivateRoute>
                  <PayoutResolution />
                </PrivateRoute>
              )}
              exact="true"
              path="/payout-resolutions/:id"
            />
            <Route
              element={(
                <PrivateRoute>
                  <LoginSessions />
                </PrivateRoute>
              )}
              exact="true"
              path="/login-session"
            />
            <Route
              element={(
                <PrivateRoute>
                  <LoginSession />
                </PrivateRoute>
              )}
              exact="true"
              path="/login-session/:id"
            />
            <Route
              element={(
                <PrivateRoute>
                  <KycSessions />
                </PrivateRoute>
              )}
              exact="true"
              path="/kyc-session"
            />
            <Route
              element={(
                <PrivateRoute>
                  <KycSession />
                </PrivateRoute>
              )}
              exact="true"
              path="/kyc-session/:id"
            />
            <Route
              element={(
                <PrivateRoute>
                  <Customers />
                </PrivateRoute>
              )}
              exact="true"
              path="/customers"
            />
            <Route
              element={(
                <PrivateRoute>
                  <Customer />
                </PrivateRoute>
              )}
              exact="true"
              path="/customers/:id"
            />
            <Route
              element={(
                <PrivateRoute>
                  <Settlements />
                </PrivateRoute>
              )}
              exact="true"
              path="/settlements"
            />
            <Route
              element={(
                <PrivateRoute>
                  <Reports />
                </PrivateRoute>
              )}
              exact="true"
              path="/reports"
            />
            <Route
              element={(
                <AdminRoute>
                  <ApiRequests />
                </AdminRoute>
              )}
              exact="true"
              path="/api-requests"
            />
            <Route
              element={(
                <PrivateRoute>
                  <ChangePassword />
                </PrivateRoute>
              )}
              exact="true"
              path="/change-password"
            />
            <Route
              element={(
                <AdminRoute>
                  <Settings />
                </AdminRoute>
              )}
              exact="true"
              path="/settings"
            />
            <Route
              element={(
                <PrivateRoute>
                  <Error404 />
                </PrivateRoute>
              )}
              path="*"
            />
            <Route
              element={<FintocWidget />}
              exact="true"
              path="/fintoc-widget"
            />
            <Route
              element={<OpenLoopPayoutWrapper />}
              exact="true"
              path="/v1/open-loop-payout/:id"
            />
          </Routes>
        </Layout>
      </Suspense>
    </ThemeProvider>
  );
};

export default AppRoutes;
