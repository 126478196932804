import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppBar from '@material-ui/core/esm/AppBar';
import Box from '@material-ui/core/esm/Box';
import Button from '@material-ui/core/esm/Button';
import Drawer from '@material-ui/core/esm/Drawer';
import Hidden from '@material-ui/core/esm/Hidden';
import IconButton from '@material-ui/core/esm/IconButton';
import Paper from '@material-ui/core/esm/Paper';
import Tooltip from '@material-ui/core/esm/Tooltip';
import {
  Bell,
  Briefcase,
  CloudDrizzle,
  Coffee,
  LifeBuoy,
  Search,
  Settings,
  Users,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { Link, NavLink as RouterLink } from 'react-router-dom';

import projectLogo from '../../assets/images/Cleo Logo.svg';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import NavItems from '../Sidebar/navItems';
import SidebarHeader from '../SidebarHeader';

const SidebarCollapsed = ({ setSidebarToggleMobile, sidebarToggleMobile }) => {
  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const sidebarMenuContent = (
    <div>
      <NavItems />
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          className="app-sidebar-wrapper-lg"
          elevation={4}
          open={sidebarToggleMobile}
          variant="temporary"
          onClose={closeDrawer}
        >
          <SidebarHeader />
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper square className="app-sidebar-collapsed-wrapper" elevation={7}>
          <AppBar color="secondary" elevation={0} position="relative">
            <div className="sidebar-collapsed-logo">
              <Box
                className="header-logo-wrapper"
                title="Carolina React Admin Dashboard with Material-UI PRO"
              >
                <Link className="header-logo-wrapper-link" to="/payin">
                  <IconButton
                    className="header-logo-wrapper-btn"
                    color="primary"
                    size="medium"
                  >
                    <img
                      alt="Carolina React Admin Dashboard with Material-UI PRO"
                      className="app-sidebar-logo"
                      src={projectLogo}
                    />
                  </IconButton>
                </Link>
              </Box>
            </div>
          </AppBar>
          <div className="app-sidebar--content">
            <PerfectScrollbar>
              <ul className="sidebar-menu-collapsed">
                <li>
                  <Tooltip arrow placement="right" title="Dashboard">
                    <Button
                      activeClassName="active"
                      className="app-sidebar-btn-wrapper"
                      component={RouterLink}
                      to="/payin"
                    >
                      <span>
                        <Settings className="app-sidebar-btn-icon" />
                      </span>
                    </Button>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip arrow placement="right" title="Events calendar">
                    <Button
                      activeClassName="active"
                      className="app-sidebar-btn-wrapper"
                      component={RouterLink}
                      to="/ApplicationsCalendar"
                    >
                      <span>
                        <CloudDrizzle className="app-sidebar-btn-icon" />
                      </span>
                    </Button>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip arrow placement="right" title="Chat">
                    <Button
                      activeClassName="active"
                      className="app-sidebar-btn-wrapper"
                      component={RouterLink}
                      to="/ApplicationsChat"
                    >
                      <span>
                        <Search className="app-sidebar-btn-icon" />
                      </span>
                    </Button>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip arrow placement="right" title="Contacts">
                    <Button
                      activeClassName="active"
                      className="app-sidebar-btn-wrapper"
                      component={RouterLink}
                      to="/ApplicationsContacts"
                    >
                      <span>
                        <Briefcase className="app-sidebar-btn-icon" />
                      </span>
                    </Button>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip arrow placement="right" title="File manager">
                    <Button
                      activeClassName="active"
                      className="app-sidebar-btn-wrapper"
                      component={RouterLink}
                      to="/ApplicationsFileManager"
                    >
                      <span>
                        <Users className="app-sidebar-btn-icon" />
                      </span>
                    </Button>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip arrow placement="right" title="Mail">
                    <Button
                      activeClassName="active"
                      className="app-sidebar-btn-wrapper"
                      component={RouterLink}
                      to="/ApplicationsMail"
                    >
                      <span>
                        <LifeBuoy className="app-sidebar-btn-icon" />
                      </span>
                    </Button>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip arrow placement="right" title="Projects">
                    <Button
                      activeClassName="active"
                      className="app-sidebar-btn-wrapper"
                      component={RouterLink}
                      to="/ApplicationsProjects"
                    >
                      <span>
                        <Coffee className="app-sidebar-btn-icon" />
                      </span>
                    </Button>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip arrow placement="right" title="App widgets">
                    <Button
                      activeClassName="active"
                      className="app-sidebar-btn-wrapper"
                      component={RouterLink}
                      to="/ApplicationsWidgets"
                    >
                      <span>
                        <Bell className="app-sidebar-btn-icon" />
                      </span>
                    </Button>
                  </Tooltip>
                </li>
              </ul>
              <div className="text-center mb-2">
                <Tooltip arrow placement="right" title="Back to dashboard">
                  <Button
                    className="text-white text-center font-size-lg d-40"
                    color="secondary"
                    component={RouterLink}
                    to="/payin"
                    variant="contained"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                </Tooltip>
              </div>
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCollapsed);
