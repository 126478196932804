export const COUNTRIES = {
  CL: 'CL', // Chilean
  MX: 'MX', // Mexico
  PE: 'PE', // Peru
};

export const COUNTRIES_TIME_ZONE = {
  CL: 'America/Santiago',
  MX: 'America/Mexico_City',
  PE: 'America/Lima',
};

export const COUNTRIES_DICT = Object.keys(COUNTRIES);
