import { COUNTRIES } from '../../../constants/Countries';
import { useAuthContext } from '../../../providers/AuthProvider';

/**
 * @param props
 * @param props.value
 */
export function amountFormat(value, country) {
  if (value == null) {
    return '';
  }

  let decimal;
  let triadSeparator;
  if (country === COUNTRIES.CL) {
    decimal = '';
    triadSeparator = '.';
  } else {
    decimal = String(value.toFixed(2)).split('.')[1];
    if (!decimal) {
      decimal = '00';
    }
    if (decimal.length === 1) {
      decimal = `${decimal}0`;
    }
    decimal = `.${decimal}`;
    triadSeparator = ',';
  }

  const isNegative = value < 0;
  let valMutable = Math.round(Math.abs(Math.trunc(value))).toString();

  let valFormated = '';

  while (valMutable.length > 3) {
    valFormated = `${triadSeparator}${valMutable.slice(-3)}${valFormated}`;
    valMutable = valMutable.slice(0, Math.max(0, valMutable.length - 3));
  }

  valFormated = valMutable + valFormated;

  return (isNegative ? '-' : '') + valFormated + decimal;
}

export default function AmountFormat({ value }) {
  const { country } = useAuthContext();
  const prefix = country === COUNTRIES.PE ? 'S/' : '$';
  return `${prefix} ${amountFormat(value, country)}`;
}
